
/**
 * @name: 用户管理-用户信息查询
 * @author:
 * @date: 2023-12-07 16:39
 * @description： 用户管理-用户信息查询
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {
  userQueryByPageApi,
  userQueryGiftCartConsumeDetailApi,
  userQueryUserAddressListApi,
  userQueryUserGiftCartListApi, userUserInfoExportApi
} from "@/apis/user/list"
import { IUserList, IUserListParam } from "@/apis/user/list/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import config from "@/config";
import {deepCopy, exportFile} from "@/utils/common";

@Component({})
export default class userList extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "用户",
        prop: "userKeywords",
        placeholder: "用户名/手机号",
        search: true,
        hide: true
      },
      {
        label: "用户名",
        prop: "nickName",
        align: "center",
        overHidden: true
      },
      {
        label: "头像",
        prop: "avatarUrl",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl,
      },
      {
        label: "手机号",
        prop: "phone",
        align: "center",
        width: 150
      },
      {
        label: "礼品卡数量",
        prop: "giftCardNumber",
        align: "center",
        slot: true
      },
      {
        label: "礼品卡余额",
        prop: "giftCardBalance",
        align: "center",
      },
      {
        label: "收货地址",
        prop: "addressNumber",
        align: "center",
        slot: true
      },
      {
        label: "首次授权登录时间",
        prop: "firstLoginTime",
        align: "center",
        type: "daterange",
        search: true,
        width: 150
      }
    ]
  }

  // 礼品卡详情弹窗
  cardDetailDialog = false
  // 礼品卡详情数据
  cardDetailList: any = []

  // 卡号消费详情弹窗
  cardSaleDetailDialog = false
  // 卡号消费详情数据
  cardSaleDetailObj: any = {}

  // 收货地址详情弹窗
  addressDetailDialog = false
  // 收货地址列表
  addressDetailList: any = []

  getList () {
    this.tableLoading = true
    const query: IUserListParam = deepCopy(this.queryParam)
    if (query.firstLoginTime && query.firstLoginTime.length) {
      query.firstLoginTimeStart = query.firstLoginTime[0]
      query.firstLoginTimeEnd = query.firstLoginTime[1]
    } else {
      query.firstLoginTimeStart = ''
      query.firstLoginTimeEnd = ''
    }
    delete query.firstLoginTime
    userQueryByPageApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  getCardStatus (status: number) {
    switch (status) {
      case 1:
        return '未激活'
      case 2:
        return '已激活'
      case 3:
        return '已使用'
      case 4:
        return '已用完'
      case 5:
        return '已过期'
    }
  }

  openUserCardDetail (userId: string) {
    userQueryUserGiftCartListApi(userId).then(e => {
      if (e) {
        this.cardDetailList = e;
        this.cardDetailDialog = true
      }
    })
  }

  openCardSaleDetail (cardNumber: string) {
    userQueryGiftCartConsumeDetailApi(cardNumber).then(e => {
      this.cardSaleDetailObj = {
        cardNumber,
        list: e
      }
      this.cardSaleDetailDialog = true
    })
  }


  openAddressDetail (userId: string) {
    userQueryUserAddressListApi(userId).then(e => {
      this.addressDetailList = e;
      this.addressDetailDialog = true
    })
  }

  exportExcel () {
    const query: IUserListParam = deepCopy(this.queryParam)
    if (query.firstLoginTime && query.firstLoginTime.length) {
      query.firstLoginTimeStart = query.firstLoginTime[0]
      query.firstLoginTimeEnd = query.firstLoginTime[1]
    } else {
      query.firstLoginTimeStart = ''
      query.firstLoginTimeEnd = ''
    }
    delete query.firstLoginTime
    userUserInfoExportApi(query).then(e => {
      exportFile(e, "用户信息.xlsx")
    })
  }

  created () {
    this.getList()
  }
}
