/**
 * @name: 用户管理-用户信息查询接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 用户管理-用户信息查询接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IUserList, IUserListParam} from "@/apis/user/list/types";

/**
 * 分页查询
 * @param param
 */
export const userQueryByPageApi = (param: IUserListParam) => get<IPageRes<IUserList[]>>("/card/user/queryByPage", param)
/**
 * 用户礼品卡信息
 * @param userId
 */
export const userQueryUserGiftCartListApi = (userId: string) => get("/card/user/queryUserGiftCartList", {userId})
/**
 * 礼品卡消费记录
 * @param cardNumber
 */
export const userQueryGiftCartConsumeDetailApi = (cardNumber: string) => get("/card/user/queryGiftCartConsumeDetail", {cardNumber})
/**
 * 用户收获地址
 * @param userId
 */
export const userQueryUserAddressListApi = (userId: string) => get("/card/user/queryUserAddressList", {userId})
/**
 * 导出
 * @param param
 */
export const userUserInfoExportApi = (param: IUserListParam) => get("/card/user/userInfoExport", param, "blob")
